import React from 'react';

import * as Styled from './styles';
import * as StyledModal from '../SearchModal/styles';
import { PreviewImageLoadingSpinner as StyledSpinner } from '../../NewRegisterForm/StepInputFile/styles';

import TypeProfilesFilters from '../TypeProfilesFilters';
import ProfileExpandedModal from '../ProfileExpandedModal';
import ProfileCard from '../ProfileCard';
import { NewButton } from '../../NewButton';
import { groupedAndSortedUsersByLetters } from '../../../lib/utils';

const ListView = ({
  totalUsersCount,
  filteredUsers,
  onSelectUser,
  showIndividualsFilter,
  showOrganizationsFilter,
  selectedUserData,
  networkErrorMessage,
  isSearchModalOpen,
  noMatches,
  resetTopAndBottomFilters,
}) => {
  const scrollToElement = (letter) => {
    const targetElement = document.querySelector(`[data-letter='${letter.toLowerCase()}']`);

    if (targetElement) {
      const topPosition = targetElement.getBoundingClientRect().top - 130;

      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    } else {
      console.log(`No element starts with the character "${letter}"`);
    }
  };

  const groupedUsers = groupedAndSortedUsersByLetters(filteredUsers, true);

  const usersAlphabetLetters = Object.keys(groupedUsers.grouped).sort();

  return (
    <div>
      <div style={{ marginTop: '160px' }}>
        <Styled.TypeProfilesFiltersContainer>
          <TypeProfilesFilters
            showIndividualsFilter={showIndividualsFilter}
            showOrganizationsFilter={showOrganizationsFilter}
          ></TypeProfilesFilters>
        </Styled.TypeProfilesFiltersContainer>
        <div style={{ textAlign: 'center', opacity: 0.66, paddingBottom: '20px' }}>
          {filteredUsers.length || totalUsersCount ? (
            <>
              <p style={{ marginTop: '1rem', marginBottom: '0', paddingBottom: '4px' }}>
                Showing {filteredUsers.length} {showIndividualsFilter.value && 'Individuals'}
                {showIndividualsFilter.value && showOrganizationsFilter.value && ' and '}
                {showOrganizationsFilter.value && 'Organisations'}
                {!showIndividualsFilter.value && !showOrganizationsFilter.value && 'profiles'}.
              </p>
              {filteredUsers.length !== totalUsersCount && (
                <p style={{ margin: '0', padding: '0' }}>
                  Clear the filters to see all {totalUsersCount} results.
                </p>
              )}
            </>
          ) : null}
        </div>

        {!noMatches && (
          <Styled.LetterFilterContainer>
            {usersAlphabetLetters?.map((letter) => {
              return (
                <Styled.LetterButton
                  key={letter}
                  onClick={(e) => {
                    scrollToElement(letter);
                  }}
                >
                  {letter.toUpperCase()}
                </Styled.LetterButton>
              );
            })}
          </Styled.LetterFilterContainer>
        )}

        {!filteredUsers.length || !totalUsersCount ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateY(-50%) translateX(-50%)',
              textAlign: 'center',
              zIndex: 12,
            }}
          >
            {networkErrorMessage === 'Loading data' ? (
              <StyledSpinner>
                <p className="sr-only">{networkErrorMessage}</p>
              </StyledSpinner>
            ) : (
              <p>{networkErrorMessage}</p>
            )}
            {noMatches && (
              <NewButton
                style={{ margin: '0 auto' }}
                variant="primary"
                size="small"
                onClick={resetTopAndBottomFilters}
              >
                Clear all filters
              </NewButton>
            )}
          </div>
        ) : (
          <StyledModal.ItemsContainer>
            {groupedUsers.sortedGroups?.map((group, idx) => (
              <li key={idx}>
                <StyledModal.ItemsList>
                  {group.map((user, index) => (
                    <ProfileCard
                      key={user.data.id}
                      userData={user.data}
                      onSelectUser={onSelectUser}
                      mobileRemoveMaxWidth={true}
                      desktopImageSize="L"
                      mobileImageSize="S"
                      imageUrl={user?.data?.image?.url}
                      width={user?.data?.image?.width}
                      height={user?.data?.image?.height}
                      showSortName
                    >
                      {index === 0 && (
                        <StyledModal.UserLetter data-letter={usersAlphabetLetters[idx]}>
                          {usersAlphabetLetters[idx]}
                        </StyledModal.UserLetter>
                      )}
                    </ProfileCard>
                  ))}
                </StyledModal.ItemsList>
              </li>
            ))}
          </StyledModal.ItemsContainer>
        )}
      </div>

      {/* This should open if loading data and we are not in search mode */}

      {!isSearchModalOpen ? (
        <div style={{ position: 'fixed', bottom: 0, zIndex: 999 }}>
          <ProfileExpandedModal
            userData={selectedUserData}
            onClose={onSelectUser}
            onSelectUser={onSelectUser}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ListView;
