import styled from 'styled-components';

import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const LetterFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-inline: 16px;

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    justify-content: start;
    overflow-x: auto;
  }
`;

export const LetterButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  opacity: ${(props) => (props.disabledButton ? 0.1 : 0.3)};
  transition: opacity 0.1s ease;
  min-width: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.BLACK};

  ${(props) => {
    if (!props.disabledButton) {
      return `
        &:hover {
        color: ${COLORS.WHITE};
        background: ${COLORS.BLACK};
        opacity: 1;
        border-radius: 100%;
        }
      `;
    }
  }}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 18px;
  }
`;

export const TypeProfilesFiltersContainer = styled.div`
  display: flex;
  gap: 6px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    position: fixed;
    bottom: 8px;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
  }
`;
