import React from 'react';

import SEO from '../components/SEO';

import PageNetworkMapLayout from '../components/NewNetwork/PageNetworkMapLayout';
import ListView from '../components/NewNetwork/ListView';

const NetworkMatchmakerPage = () => {
  return (
    <>
      <SEO title="Network — Matchmaker"></SEO>
      <PageNetworkMapLayout pageType="list" CurrentView={ListView}></PageNetworkMapLayout>
    </>
  );
};

export default NetworkMatchmakerPage;
