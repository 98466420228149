import styled, { css } from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../../utils/theme';

export const QuestionContainer = styled.div``;

export const DropZoneWithPreviewsContainer = styled.div`
  position: relative;
  max-width: ${(props) => (props.maxFiles > 1 ? '800px' : '480px')};
  margin: 0 auto;

  aspect-ratio: 3/4;

  @media screen and (min-width: ${BREAKPOINTS.MOBILE}px) {
    aspect-ratio: ${(props) => (props.maxFiles > 1 ? 2.2 : 1)};
  }
`;

export const DropZone = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='26' ry='26' stroke='black' stroke-width='2.5' stroke-dasharray='6%2c10' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  border-radius: 26px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover > .dragging-overlay {
    opacity: 1;
  }

  & input:focus-visible ~ .dragging-overlay {
    outline: 3px solid black;
    outline-offset: 2px;
    opacity: 1;
  }
`;

export const DraggingOverlay = styled.div`
  border-radius: 26px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(20, 0, 10, 0.06);

  transition: opacity 0.15s ease-out;

  pointer-events: none;

  opacity: ${(props) => (props.isDragging ? 1 : 0)};
`;

export const InputLabel = styled.label`
  text-decoration: underline;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 2;
  }
`;

export const PreviewList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  justify-content: center;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: ${BREAKPOINTS.MOBILE}px) {
    flex-direction: row;
  }
`;

export const PreviewListItem = styled.li`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 1/1;

  height: ${(props) => (1 / props.maxFiles) * 100}%;

  @media screen and (min-width: ${BREAKPOINTS.MOBILE}px) {
    width: ${(props) => (1 / props.maxFiles) * 100}%;
    height: auto;
  }

  flex-shrink: 0;

  &:nth-child(1) {
    transform: rotate(-5deg);
  }
  &:nth-child(2) {
    transform: rotate(6deg);
  }
  &:nth-child(3) {
    transform: rotate(-4.5deg);
  }
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  outline: 2px solid white;

  &[data-image-contain-size='true'] {
    background: white;
    outline-color: ${COLORS.BLUE};
    object-fit: contain;
  }
`;

export const PreviewImageActionsAndStatusContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  pointer-events: auto;
`;

export const PreviewImageDeleteBtn = styled.button`
  height: 32px;
  width: 32px;

  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  background: none;

  color: ${COLORS.BLUE};
  background: white;

  border-radius: 100%;
  &:hover {
    color: ${COLORS.RED};
  }

  &:focus-visible {
    outline-offset: 2px;
    outline: 2px solid currentColor;
  }
`;

export const PreviewImageId = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;

  border: 1px solid currentColor;

  color: ${COLORS.BLUE};
  background: white;
  border-radius: 100%;

  > span {
    position: absolute;
    bottom: 7px;
  }
`;

export const PreviewImageLoadingSpinner = styled.div`
  height: 32px;
  width: 32px;
  position: relative;

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: ${COLORS.BLUE};
    animation: spinner 0.6s linear infinite;
  }
`;

export const DropZoneExplanation = styled.p`
  ${(props) =>
    props.showAtBottom &&
    css`
      margin-top: auto;
    `}
`;

export const InputFooter = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: ${COLORS.DARK_GREY};
  column-gap: 24px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;
